// Forms ———————————————————————————————————————————————————————————————

.form-blocks {
  margin-bottom: rem(#{'var(--space-biggest) * 2'});
}

.form-block {
  margin-top: var(--space-rem-big);

  & > h2,
  &._submit {
    margin-top: rem(var(--space-biggest));
  }
}

.form-block._inline {
  display: flex;

  > .form-block {
    flex: auto;
    margin-top: 0;

    & + .form-block {
      margin-left: rem(12);
    }
  }

  > .form-block._min {
    flex: none;
  }
}

.form-block-label {
  display: block;
  padding-bottom: var(--space-rem-small);
}

.form-block-hint {
  color: var(--contrast-darker);

  .form-block-controls + & {
    margin-top: var(--space-rem-small);
  }
}

.form-block-controls {
  display: flex;

  button,
  .button {
    & + button,
    & + .button {
      margin-left: var(--space-rem-small);
    }
  }
}

.form-block-control {
  // display: flex;
  flex: auto;
  min-width: 0;

  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea {
    flex: auto;
    width: 100%;
  }

  & + .form-block-control {
    margin-left: rem(var(--space-smallest));
  }

  &._min {
    flex: none;
  }
}
