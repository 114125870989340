

















































































@import '../assets/scss/util';

.page-home {
  header {
    padding: rem(32) 0;
  }

  header h1 {
    margin: 0;
  }

  & .headline {
    font-size: clamp(2rem, 6vw, 5rem);
    line-height: (36/32);
    margin: 0 0 rem(12);
  }

  & .tagline {
    font-size: clamp(1.5rem, 4vw, 3rem);
    line-height: (28/24);
    margin: 0 0 em(40, 24);
  }
}
