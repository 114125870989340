

















































































.time-string {
  white-space: nowrap;
}

.time-string[data-unit='second'] {
  font-variant: tabular-nums;
}
