



































































































































































































































@import '../assets/scss/util';

.screen-more {
  & .bar h1 {
    margin: 0 0 rem(7);
  }

  & main {
    padding-bottom: rem(80);
  }

  & h2 {
    margin: rem(36) auto rem(12);
  }
  & h3 {
    margin: rem(48) auto rem(12);
  }

  & p {
    margin-top: 0;
  }

  & .message {
    margin-top: var(--space-rem-small);
  }

  & input[readonly] {
    --border-color: var(--contrast-light);
    --background-color: transparent;
  }
}

footer {
  box-shadow: 0 -1px 0 var(--contrast-lighter);
  padding: rem(12) 0 rem(96);

  & p {
    @include font-size(13, 16);
  }
}
