// Start ———————————————————————————————————————————————————————————————

main {
  padding-top: max(0, env(safe-area-inset-top));
  padding-bottom: rem(120);
}

input {
  @extend .tabular;
  scroll-margin-top: em((92 + 28 + 8)); // bar + label + extra
}

.tabular {
  font-feature-settings: 'tnum' 1;
  font-variant-numeric: tabular-nums;
  text-indent: (1em/-20);
}

.list-copy {
  @extend .layout;
  margin-top: var(--space-rem-biggest);
  text-align: center;
}

.bar {
  min-height: rem(44);
  background-color: var(--color-root-backdrop);
  backdrop-filter: saturate(1.8) blur(20px);
  box-shadow: 0 1px 0 var(--contrast-lighter);
  position: sticky;
  top: 0;
  z-index: 1;
  -webkit-overflow-scrolling: auto;
}

.bar-block {
  @extend .layout;
  display: flex;
  align-items: center;

  h1 {
    line-height: (32/32);
    margin: rem(6) 0 rem(10);
  }
}

.bar-button {
  flex: auto;
  flex-basis: 0;
  font-size: em(17);
  line-height: (20/17);
  border: none;
  padding: rem(12) rem(var(--screen-padding-x));
  max-width: calc(50% + ((1rem / 16) * var(--screen-padding-x)));
  margin-left: -rem(var(--screen-padding-x));

  &,
  &:focus,
  &:hover {
    background-color: transparent;
  }

  &:focus {
    outline-offset: -3px;
  }

  &._end {
    text-align: right;
    margin-left: auto;
    margin-right: -rem(var(--screen-padding-x));
  }
}

button.bar-button {
  @include focus-outline($offset: -3px);
}

.list-item {
  display: block;
  box-shadow: 0 1px 0 var(--contrast-lightest);

  &-inner {
    width: var(--layout-base);
    max-width: var(--layout-measure);
    margin: 0 auto;
    padding: rem(16) 0;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
  }
}

// Counters

.counter {
  @extend .list-item;
  overflow: auto;

  &-inner {
    @extend .list-item-inner;
  }
}

.counter-count {
  font-size: clamp(1rem, 11vw, #{em(72)});
  line-height: 1;
  padding-bottom: rem(var(--space-smaller));
  text-indent: (1em/-20);
}

// .counter-unit {
//   text-transform: uppercase;
//   font-size: em(13);
//   letter-spacing: em(300, 1000);
// }

.date-item {
  time {
    font-size: clamp(1rem, 3vw, #{em(24)});
    margin: 0;
  }
}

.sentence-item {
  @extend .tabular;

  p {
    font-size: clamp(rem(18), 6vw, #{em(36)});
    margin: 0;
  }
}
