// Colour ——————————————————————————————————————————————————————————————

$color-white: #fff;
$color-black: hsl(220, 40%, 15%);

$color-red: hsl(5, 100%, 60%);
$color-orange: hsl(30, 100%, 50%);
$color-yellow: hsl(45, 100%, 50%);
$color-green: hsl(130, 60%, 50%);
$color-blue-light: hsl(200, 90%, 70%);
$color-blue: hsl(220, 100%, 50%);
$color-purple: hsl(240, 70%, 60%);

$color-key: $color-blue;
$color-key-lighter: mix($color-white, $color-key, 20);
$color-key-darker: mix($color-black, $color-key, 20);
$color-key-50: fade-out($color-key, 0.5);
$color-key-40: fade-out($color-key, 0.6);
$color-key-30: fade-out($color-key, 0.7);
$color-key-20: fade-out($color-key, 0.8);
$color-key-10: fade-out($color-key, 0.9);
$color-text-key: $color-key;

$color-good: $color-green;
$color-good-lighter: lighten($color-good, 12);
$color-good-darker: darken($color-good, 8);
$color-good-50: fade-out($color-good, 0.5);
$color-good-40: fade-out($color-good, 0.6);
$color-good-30: fade-out($color-good, 0.7);
$color-good-20: fade-out($color-good, 0.8);
$color-good-10: fade-out($color-good, 0.9);
$color-text-success: lighten($color-good, 10);

$color-bad: $color-red;
$color-bad-lighter: lighten($color-bad, 12);
$color-bad-darker: darken($color-bad, 6);
$color-bad-50: fade-out($color-bad, 0.5);
$color-bad-40: fade-out($color-bad, 0.6);
$color-bad-30: fade-out($color-bad, 0.7);
$color-bad-20: fade-out($color-bad, 0.8);
$color-bad-10: fade-out($color-bad, 0.9);
$color-text-error: darken($color-bad, 10);

$tint-darkest: fade-out($color-white, 0.4);
$tint-darker: fade-out($color-white, 0.5);
$tint-dark: fade-out($color-white, 0.6);
$tint-mid: fade-out($color-white, 0.7);
$tint-light: fade-out($color-white, 0.8);
$tint-lighter: fade-out($color-white, 0.88);
$tint-lightest: fade-out($color-white, 0.94);

$shade-darkest: fade-out($color-black, 0.2);
$shade-darker: fade-out($color-black, 0.4);
$shade-dark: fade-out($color-black, 0.5);
$shade-mid: fade-out($color-black, 0.7);
$shade-light: fade-out($color-black, 0.85);
$shade-lighter: fade-out($color-black, 0.9);
$shade-lightest: fade-out($color-black, 0.94);

:root {
  --color-white: #{$color-white};
  --color-black: #{$color-black};
  --color-red: #{$color-red};
  --color-blue: #{$color-blue};
  --color-green: #{$color-green};

  --color-key: #{$color-key};
  --color-key-contrast: #{$color-white};
  --color-key-lighter: #{$color-key-lighter};
  --color-key-darker: #{$color-key-darker};
  --color-key-50: #{$color-key-50};
  --color-key-40: #{$color-key-40};
  --color-key-30: #{$color-key-30};
  --color-key-20: #{$color-key-20};
  --color-key-10: #{$color-key-10};
  --color-text-key: #{$color-text-key};

  --color-good: #{$color-good};
  --color-good-contrast: #{$color-white};
  --color-good-lighter: #{$color-good-lighter};
  --color-good-darker: #{$color-good-darker};
  --color-good-50: #{$color-good-50};
  --color-good-40: #{$color-good-40};
  --color-good-30: #{$color-good-30};
  --color-good-20: #{$color-good-20};
  --color-good-10: #{$color-good-10};
  --color-text-success: #{$color-text-success};

  --color-bad: #{$color-bad};
  --color-bad-contrast: #{$color-white};
  --color-bad-lighter: #{$color-bad-lighter};
  --color-bad-darker: #{$color-bad-darker};
  --color-bad-50: #{$color-bad-50};
  --color-bad-40: #{$color-bad-40};
  --color-bad-30: #{$color-bad-30};
  --color-bad-20: #{$color-bad-20};
  --color-bad-10: #{$color-bad-10};
  --color-text-error: #{$color-text-error};

  --color-root: var(--color-white);
  --color-root-backdrop: #{fade-out(white, 0.28)};

  --color-contrast: var(--color-black);
  --color-shade: var(--color-black);

  --contrast-darkest: #{$shade-darkest};
  --contrast-darker: #{$shade-darker};
  --contrast-dark: #{$shade-dark};
  --contrast-mid: #{$shade-mid};
  --contrast-light: #{$shade-light};
  --contrast-lighter: #{$shade-lighter};
  --contrast-lightest: #{$shade-lightest};

  --shade-darkest: #{$shade-darkest};
  --shade-darker: #{$shade-darker};
  --shade-dark: #{$shade-dark};
  --shade-mid: #{$shade-mid};
  --shade-light: #{$shade-light};
  --shade-lighter: #{$shade-lighter};
  --shade-lightest: #{$shade-lightest};

  --color-text: var(--color-contrast);
  --color-focus: var(--color-key-40);
}
