


























































@import '../assets/scss/util';

.select-timezone {
  position: relative;
}

.select-timezone-display {
  background-color: var(--input-background-color);
  border: 1px solid transparent;
  border-radius: var(--controls-radius);
  font-size: em(14);
  line-height: (24/14);
  padding: rem(var(--input-padding-y)) rem(var(--input-padding-y));
}

.select-timezone-control {
  & select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    background-color: transparent;
    background-image: none !important;
    padding: 0;
  }
}
