















































@import '../assets/scss/util';

.times-item {
  display: block;
  line-height: 1.25;
  box-shadow: 0 1px 0 var(--contrast-lightest);
}

.times-item-inner {
  width: var(--layout-base);
  max-width: var(--layout-measure);
  margin: 0 auto;
  padding: rem(16) 0;
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
}

a.times-item {
  &,
  &:focus,
  &:hover {
    color: var(--color-text);
  }

  &:hover {
    background-color: var(--contrast-lightest);
  }

  &:focus {
    outline-offset: -3px;
  }
}

div.times-item {
  background-color: var(--color-root);
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--contrast-lightest),
    0 1px 1rem var(--contrast-lighter);
}

div > .times-item-inner {
  padding: rem(16) rem(18);
}

.times-item-label {
  font-size: clamp(1rem, 5vw, #{em(32)});
}
.times-item-time {
  font-size: clamp(1.5rem, 8vw, #{em(48)});
  text-indent: (1em/-20);
}
