// Controls ————————————————————————————————————————————————————————————

$icon_arrow-down-dark: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 40'><path opacity='.35' d='M63.36 8.47L33.47 38.36a2 2 0 01-2.95 0L.64 8.47A2.21 2.21 0 010 7a2.21 2.21 0 01.64-1.47l3.21-3.21a2.09 2.09 0 011.47-.64 2.21 2.21 0 011.47.64L32 27.52l25.2-25.2a2 2 0 012.95 0l3.21 3.21A2.21 2.21 0 0164 7a2.21 2.21 0 01-.64 1.47z'/></svg>");
$icon_arrow-down-light: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 40'><path opacity='.35' fill='white' d='M63.36 8.47L33.47 38.36a2 2 0 01-2.95 0L.64 8.47A2.21 2.21 0 010 7a2.21 2.21 0 01.64-1.47l3.21-3.21a2.09 2.09 0 011.47-.64 2.21 2.21 0 011.47.64L32 27.52l25.2-25.2a2 2 0 012.95 0l3.21 3.21A2.21 2.21 0 0164 7a2.21 2.21 0 01-.64 1.47z'/></svg>");

:root {
  --controls-radius: 2px;

  --input-padding-x: 15;
  --input-padding-y: 9;
  --input-color: var(--color-text);
  --input-background-color: var(--contrast-lighter);
  --input-border-color: transparent;
  --input-hover-border-color: var(--color-key);
  --input-focus-color: var(--color-focus);
}

// Placeholders

%placeholder {
  color: inherit;
  opacity: (2/5);
}

::placeholder {
  @extend %placeholder;
}

// Inputs

input[type='text'],
input[type='email'],
input[type='search'],
textarea {
  appearance: none;
  box-shadow: none;

  &::-ms-clear {
    display: none;
  }
}

%input-base {
  --color: var(--input-color);
  --background-color: var(--input-background-color);
  --border-color: var(--input-border-color);
  --hover-border-color: var(--input-hover-border-color);
  --focus-color: var(--input-focus-color);
  --padding-x: var(--input-padding-x);
  --padding-y: var(--input-padding-y);
  --radius: var(--controls-radius);

  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  font: inherit;
  line-height: (24/16);
  padding: rem(var(--padding-y)) rem(var(--padding-x));
  transition: border-color 0.1s, background-color 0.1s, box-shadow 0.1s;

  &:focus,
  &:hover {
    outline: none;
    border-color: var(--hover-border-color);
  }

  @include focus-shadow(var(--focus-color));
}

input,
.input {
  @extend %input-base;
}

input[type='date'],
input[type='time'] {
  --padding-x: 12;
  appearance: none;
  min-height: calc((20em / 16) + (10rem / 16));
  min-width: 0;

  &[required]::-webkit-clear-button {
    appearance: none;
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: 0;
    margin: 0;
  }

  &::-webkit-calendar-picker-indicator {
    // width: em(20);
    // height: em(20);
    padding: rem(10);
    margin: rem(-10);
    margin-inline-start: 0;
    opacity: (2/5);
    transition: opacity 0.15s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }

  &::-webkit-date-and-time-value {
    margin-top: 0;
    margin-bottom: 0;
  }

  &::-webkit-datetime-edit {
    min-width: em(52);
  }

  &::-webkit-datetime-edit-minute-field,
  &::-webkit-datetime-edit-hour-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-year-field {
    padding: 0 2px;
  }
}

// Select

%select-base {
  @extend %input-base;

  appearance: none;
  background-position: right rem(var(--padding-x)) center;
  background-repeat: no-repeat;
  background-size: rem(9.5) auto;
  padding-right: rem(#{'var(--padding-x) * 2'});

  &[multiple] {
    height: auto;
    padding-right: rem(var(--padding-x));
    background-image: none;
  }

  &:not([multiple]):not([size]) {
    background-image: $icon_arrow-down-dark;

    @media (prefers-color-scheme: dark) {
      background-image: $icon_arrow-down-light;
    }
  }
}

select,
.select {
  @extend %select-base;
}
