






















































































































@import 'assets/scss/util';

dialog {
  background-color: var(--color-root);
  color: var(--color-text);
  position: fixed;
  bottom: 0;
  z-index: 99;
  border: none;
  width: max-content;
  max-width: 100%;
  height: auto;
  padding: 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 0 12px var(--contrast-light), 0 0 0 1px var(--contrast-lighter);

  text-align: center;

  & form {
    padding: rem(28) rem(20);
    padding-bottom: calc((60rem / 16) + env(safe-area-inset-bottom));
  }

  & .layout {
    width: auto;
    max-width: rem(366);
  }

  & h1 {
    @include font-size(21, 24);
    margin: 0;
  }

  @media (min-width: 480px) {
    bottom: rem(40);
    border-radius: 12px;

    & form {
      padding: rem(28);
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s cubic-bezier((1/4), (10/12), (1/10), 1);
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);

  @media (min-width: 480px) {
    transform: translateY(calc(100% + (40rem / 16)));
  }
}
